import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ dashboard }) => ({
	container: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
	},
	content: {
		padding: dashboard?.innerContent?.paddingTop,
		paddingBottom: 0,
		overflow: "auto",
		height: "max-content",
		maxHeight: "100%",
		width: "100%",
		margin: 0,
		position: "relative",
	},
}));