import React from "react";

import { Box } from "@material-ui/core";

import { Typography } from "~/components/Typography";
import { InfoCard } from "~/components/InfoCard";

import { useStyles } from "./styles";

const NoInvoices = () => {
	const styles = useStyles();
	return (
		<InfoCard className={styles.card}>
			<Box className={styles.content}>
				<Typography variant="caption">No invoices yet</Typography>
			</Box>
		</InfoCard>
	);
};

export default NoInvoices;
