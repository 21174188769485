import { useQuery } from "@apollo/client";

import { isNil } from "lodash";

import { GetCompanyInvoices } from "./query/invoices";

import { GetCompanyInvoicesQuery, GetCompanyInvoicesQueryVariables } from "./graphql/types";

export const useInvoicesList = (companyId: number) => {

	const variables: GetCompanyInvoicesQueryVariables = {
		companyId
	};

	const { data, loading, error } = useQuery<
		GetCompanyInvoicesQuery,
		GetCompanyInvoicesQueryVariables
	>(GetCompanyInvoices, { variables, skip: isNil(companyId) });

	return {
		invoices: data?.getCompanyInvoices,
		loading,
		error: error?.message,
	};
};