import gql from "graphql-tag";

export const GetCompanyInvoices = gql`
	query GetCompanyInvoices(
		$companyId: Int!
	) {
		getCompanyInvoices(
            companyId: $companyId
        ) {
            balance
            company_name
            customer_name
            currency_code
            currency_symbol
            date
            due_date
            invoice_number
            invoice_id
            invoice_url
            status
            total
        }
	}
`;