import React, { useMemo } from "react";

import { useAuth } from "@api/auth";
import { useInvoicesList } from "@api/invoices";

import { AutocorpBlock } from "@settings/AutocorpBlock";
import { CriticalError } from "@settings/CriticalError";
import { GlobalLoader } from "@settings/GlobalLoader";

import { Box, Grid } from "@material-ui/core";

import NoInvoices from "../components/NoInvoices";
import InvoicesTable from "../components/InvoicesTable";

import { useStyles } from "./styles";

const InvoicesDisplay: React.FC = () => {
	const { user, selectedCompany } = useAuth();
	const styles = useStyles();

	const { invoices, loading, error } = useInvoicesList(selectedCompany?.id!);

	const content = useMemo(() => {
		if (user?.autocorpAdmin && !selectedCompany) {
			return <AutocorpBlock />;
		}

		let content: React.ReactNode;

		if (error) {
			content = (
				<CriticalError
					message={"Unable to retrieve Invoices!"}
					error={error}
				/>
			);
		} else if (loading) {
			content = <GlobalLoader />;
		} else if (!invoices?.length) {
			content = <NoInvoices />;
		} else {
			content = (
				<Box className={styles.content}>
					<InvoicesTable data={invoices || []} loading={loading} />
				</Box>
			);
		}

		return (
			<Grid
				container
				spacing={2}
				className={styles.content}
				style={{
					height: loading ? "100%" : undefined,
				}}
			>
				{content}
			</Grid>
		);
	}, [selectedCompany, user, invoices, loading, error]);

	return <Box className={styles.container}>{content}</Box>;
};

export default InvoicesDisplay;
