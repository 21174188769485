import React from "react";

import { createSettingsView, ViewComponent } from "@settings/view";

import InvoicesDisplay from "./_lib/Display";

const Invoices: ViewComponent = () => {
	return <InvoicesDisplay />;
};

export default createSettingsView(Invoices, {
	title: "Invoices",
	internalPad: true,
	internalScroll: true,
});
