import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: 240,
    },
    card: {
        width: "100%",
    }
}));